import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  breakpoints,
  ZIndex,
  useResolution,
  AlgoliaProduct,
  DistributionCenter,
  slugify,
  useLocation,
  RETURNABLE_PLUS_BOTTLE_TAG_BO,
  RETURNABLE_PLUS_BOTTLE_TAG_CL,
  DISPONSABLE_TAG_BO,
  DISPONSABLE_TAG_CL,
  RETURNABLE_TAG_BO,
  RETURNABLE_TAG_CL,
} from '@ecommerce/shared'
import { Configure } from 'react-instantsearch-dom'
import { navigate } from '@reach/router'
import { Anchor } from '../common'
import { Icon } from '../Icon/Icon'
import Breadcrumb from '../Breadcrumbs'
import Filter from '../Algolia/Filter'
import Loading from '../ShowLoading'
import ResultProducts from '../Algolia/ResultProducts'
import { InfoEmptySearch, sortListAlgolia } from '../../mocks'
import { Filter as ContentfulFilter } from '../../graphql/contentfulTypes'
import { useAppDispatch, useAppSelector } from '../../store'
import {
  resetFilters,
  resetRefinementLists,
  selectConfigure,
  selectIndexName,
  selectRefinementList,
  setRefinementList,
} from '../../store/algolia/searchStateSlice'
import Brands from './Brands'
import CustomSort from '../Algolia/CustomSort'
import useRefinementListQuery from '../../hooks/useRefinementListQuery'
import useSelectedRefinementList from '../../hooks/useSelectedRefinementList'
import { formatFilterByCategoryNames } from '../../utils/algolia'
import ReturnabilityFilter from '../ReturnabilityContainer/ReturnabilityFilter'

declare const window: Window

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .CategoryContainer__ {
    &breadcrumb {
      width: 100%;
      background: ${(props) => props.theme.colors.white};
      z-index: ${ZIndex.low};
      > div {
        height: 38px;
        padding: 22px 0 0 17px;
        background-color: ${(props) => props.theme.colors.white};
      }
      .Breadcrumbs__ {
        &go_back {
          display: none;
        }
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .CategoryContainer__ {
      &breadcrumb {
        width: 100%;
        > div {
          height: 35px;
          padding: 23px 0 0 0;
          background-color: ${(props) => props.theme.colors.white};
        }
      }
    }
  }
`
const Wrapper = styled.div<{ isOpenFilter: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .CategoryContainer__ {
    &left {
      width: ${(props) => (props.isOpenFilter ? '100%' : '0')};
      height: 100vh;
      top: 0;
      right: 0;
      position: fixed;
      transition: 100ms;
      z-index: ${ZIndex.ultraHigh};
      &-title,
      &-go_back {
        display: none;
      }
    }
    &right {
      width: 100%;
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .CategoryContainer__ {
      &left {
        width: ${(props) => (props.isOpenFilter ? '375px' : '0')};
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .CategoryContainer__ {
      &left {
        width: 25%;
        height: auto;
        right: auto;
        position: relative;
        z-index: inherit;
        &-go_back {
          width: 100%;
          height: 52px;
          padding-top: 10px;
          display: flex;
          align-items: center;
          svg {
            width: 17px;
            height: 17px;
          }
          span {
            width: auto;
            padding-left: 10px;
            font-size: 14px;
            color: ${(props) => props.theme.colors.black};
            cursor: pointer;
          }
        }
        .CategoryFilter__ {
          &clear {
            height: 35px;
            padding: 10px 3px;
          }
          &option {
            padding: 0;
          }
        }
      }
      &right {
        width: 70%;
      }
    }
  }
`
const Title = styled.div`
  width: 100%;
  height: 50px;
  padding: 0 20px;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  z-index: ${ZIndex.low};
  span {
    font-size: 24px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.black};
  }
  .CategoryContainer__ {
    &right {
      &-title {
        &-order {
          /* hidden element and style for this review */
          display: none;
        }
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    height: 42px;
    padding: 0 0 0 20px;
    background-color: ${(props) => props.theme.colors.white};
    justify-content: space-between;
    .CategoryContainer__ {
      &right {
        &-title {
          &-sort {
            align-items: flex-end;
            cursor: pointer;
            &-icon {
              object-fit: contain;
              color: ${(props) => props.theme.colors.black};
              margin-left: auto;
            }
          }
          &-name {
            width: 75px;
            height: 18px;
            font-size: 13px;
            font-weight: normal;
            color: ${(props) => props.theme.colors.black};
          }
        }
      }
    }
  }
`

const BlockedBox = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(3, 15, 28, 0.79);
  position: fixed;
  z-index: ${ZIndex.medium};
`

function blockedScreen(isBlocked: boolean) {
  const element = window.document.getElementById('layout')
  if (element) {
    if (isBlocked) {
      element.style.position = 'fixed'
    } else {
      element.style.position = 'absolute'
    }
  }
}

interface Data {
  id: string
  name: string
  brandScore?: number
  contentfulid?: string
  image: {
    file: {
      url: string
    }
  }
}
interface Node {
  node: Data
}

interface Props {
  currentDistributionCenter: DistributionCenter
  categoryLabel: string
  categoryNames: string[]
  brands: Node[]
  categoryFilters: ContentfulFilter[]
  excludeFilters?: { param: string; value: string }[]
  refinementListSelected: string[]
  refinementList: { [key: string]: string[] }
  onHitClick?: (hit?: AlgoliaProduct) => void
}

interface QueryParams {
  [key: string]: Array<string>
}

const CategoryContainer = (props: Props) => {
  const { textByCountry } = useLocation()

  const categoriesFilters = useMemo(
    () => [
      {
        label: textByCountry('Producto + Envase', 'Producto + Botella'),
        itemType: [textByCountry(RETURNABLE_PLUS_BOTTLE_TAG_CL, RETURNABLE_PLUS_BOTTLE_TAG_BO)],
        attribute: 'returnabilityLabel',
        index: 0,
      },
      {
        label: textByCountry('Retornar mi envase', 'Retornar mi botella'),
        itemType: [textByCountry(RETURNABLE_TAG_CL, RETURNABLE_TAG_BO)],
        attribute: 'returnabilityLabel',
        index: 0,
      },
      {
        label: 'Desechable',
        itemType: [textByCountry(DISPONSABLE_TAG_CL, DISPONSABLE_TAG_BO)],
        attribute: 'returnabilityLabel',
        index: 0,
      },
    ],
    [textByCountry],
  )

  const dispatch = useAppDispatch()

  const configure = useAppSelector(selectConfigure)
  const indexName = useAppSelector(selectIndexName)

  const selectedRefinementList = useAppSelector(selectRefinementList)

  const {
    brands,
    categoryNames,
    categoryLabel,
    categoryFilters,
    currentDistributionCenter,
    refinementListSelected,
    onHitClick,
    refinementList,
  } = props

  const slugLocation = slugify(currentDistributionCenter.commerceLayer.stockLocation.name)

  const filters: string[] = [...categoryFilters.map((category) => category.filter), 'brandName']

  const [isOpenFilter, setIsOpenFilter] = useState(false)

  const { isDesktop } = useResolution()

  function onOpenFilter() {
    setIsOpenFilter(true)
    blockedScreen(true)
  }

  function onCloseFilter() {
    setIsOpenFilter(false)
    blockedScreen(false)
  }

  const items = [
    {
      name: 'Inicio',
      url: `/`,
    },
    {
      name: 'Categorías',
      url: `/category`,
    },
    {
      name: categoryLabel,
      url: '',
    },
  ]

  const categoryDetails: Record<string, { filterLabel: string; urlImage: string }> = useMemo(
    () => ({
      Bebidas: {
        filterLabel: '¿Qué tipo de bebida buscas?',
        urlImage:
          'https://images.ctfassets.net/16npdkkoi5mj/1HEdv5Pe51cAISCUXRHMLN/71ebb59575cc2ea846776bb3edab831f/Cuanto_ahorras_-_bebidas_Image.png',
      },
      Gaseosa: {
        filterLabel: '¿Qué tipo de gaseosa buscas?',
        urlImage:
          'https://images.ctfassets.net/16npdkkoi5mj/1HEdv5Pe51cAISCUXRHMLN/71ebb59575cc2ea846776bb3edab831f/Cuanto_ahorras_-_bebidas_Image.png',
      },
    }),
    [],
  )

  const returnabilityFilterInCategory = useMemo(() => categoryDetails[categoryLabel], [categoryLabel, categoryDetails])

  useRefinementListQuery(filters, setRefinementList, null)
  useSelectedRefinementList(selectedRefinementList, null, undefined)

  useEffect(() => {
    return () => {
      dispatch(resetFilters())
      dispatch(resetRefinementLists())
    }
  }, [dispatch])

  return (
    <Container>
      <Loading />
      {isOpenFilter && <BlockedBox />}
      <div className="CategoryContainer__breadcrumb">
        <Breadcrumb items={items} />
      </div>
      <Wrapper isOpenFilter={isOpenFilter}>
        <div className="CategoryContainer__left">
          <Anchor className="CategoryContainer__left-go_back" onClick={() => navigate(`/`)}>
            <Icon iconId="arrow_left" />
            <span>Volver</span>
          </Anchor>
          <Filter
            onCloseFilter={onCloseFilter}
            filters={categoryFilters}
            refinementListSelected={refinementListSelected}
          />
        </div>

        <div className="CategoryContainer__right">
          <Title className="CategoryContainer__right-title">
            <span>{categoryLabel}</span>
            {isDesktop && <CustomSort items={sortListAlgolia} defaultRefinement={indexName} />}
            <div className="CategoryContainer__right-title-order">
              <Anchor>
                <Icon iconId="sort" />
              </Anchor>
              <span className="CategoryContainer__right-title-name">Destacados</span>
            </div>
          </Title>

          <Brands
            brands={brands.map((brand) => ({
              label: brand?.node?.name || '',
              pimId: brand?.node?.contentfulid || undefined,
              src: `https:${brand?.node?.image?.file?.url || ''}`,
              score: brand.node?.brandScore ?? 0,
            }))}
            onOpenFilter={onOpenFilter}
          />

          <Configure
            filters={`${configure.filters} AND slugLocation:${slugLocation} AND ${formatFilterByCategoryNames(
              categoryNames,
            )}`}
            hitsPerPage={configure.hitsPerPage}
          />
          {returnabilityFilterInCategory && (
            <ReturnabilityFilter
              categoriesFilters={categoriesFilters}
              filterLabel={returnabilityFilterInCategory.filterLabel}
              urlImage={returnabilityFilterInCategory.urlImage}
            />
          )}
          <ResultProducts
            urlImage={InfoEmptySearch.urlImage}
            text={InfoEmptySearch.text}
            onHitClick={onHitClick}
            listName={categoryLabel}
          />
        </div>
      </Wrapper>
    </Container>
  )
}

export default CategoryContainer
