import React, { useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { breakpoints, useShoppingCart, useAuth, getStoredDistributionCenter } from '@ecommerce/shared'
import Layout from '../components/Layout'
import CategoryContainer from '../components/CategoryContainer'
import withPageTransition from '../components/withPageTransition'
import { useCatalogHitClick } from '../hooks/useCatalogHitClick'
import { PgPageProps, FlatLocationProductCategory } from '../types/PgPages'
import { CategoryResponse, BrandResponse } from '../graphql/categoryQuery'
import { Filter, BannerFluidType } from '../graphql/contentfulTypes'
import useContentfulGlobal from '../hooks/useContentfulGlobal'
import useTheme from '../hooks/useTheme'
import { BannerFluidWidget } from '../components/BannerFluid'
import { sendPageViewEvent } from '../utils/events'

type CategoryNode = {
  label: string
  title?: string
  description?: string
  keywords?: string[]
  mainCategoryNames: string[]
  urlImage: string
  brands: BrandResponse[]
  categoryLabels: CategoryResponse[]
  productCategories: FlatLocationProductCategory[]
  filters?: Filter[]
  theme?: {
    contentful_id: string
  }
  hasDefaultFilters: boolean
  banner: BannerFluidType
}

const WrapperContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 231px);
  @media (${breakpoints.tabletPortrait.min}) {
    min-height: calc(100vh - 194px);
  }
  @media (${breakpoints.desktop.min}) {
    min-height: calc(100vh - 212px);
    padding: 40px 20px 0 20px;
  }

  @media (${breakpoints.desktop.medium}) and (${breakpoints.desktop.max}) {
    padding: 40px;
  }
`

const CategoryView = (props: PgPageProps<{ category: CategoryNode }>) => {
  const currentDistributionCenter = getStoredDistributionCenter() || props.pageContext.currentDistributionCenter

  const { category, productCategories, theme } = props.pageContext
  const { location } = props

  const { filters, hasDefaultFilters } = category

  const refinementList = location?.state?.refinementList
  const refinementListSelected = refinementList && Object.keys(refinementList).filter((item) => refinementList[item])

  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, event: 'categoryView', title: category.label })
  }, [])

  const { defaultCategoryFilters } = useContentfulGlobal()
  const themeId = location?.state?.themeId || theme?.contentful_id
  const { mergeThemes } = useTheme(themeId)

  const defaultFilters = () => {
    if (hasDefaultFilters || hasDefaultFilters === null) return defaultCategoryFilters
    return []
  }

  const onCatalogHitClick = useCatalogHitClick({
    listName: `Categorías - ${category.label}`,
    themeId,
  })

  return (
    <ThemeProvider theme={mergeThemes}>
      <Layout
        id="layout"
        description={category.description}
        title={category.title || category.label}
        keywords={category.keywords}
        pathName="/category"
        categories={productCategories}
      >
        {category.banner ? <BannerFluidWidget contentfulId={category.banner.contentful_id} /> : null}
        <WrapperContainer>
          <CategoryContainer
            currentDistributionCenter={currentDistributionCenter}
            categoryFilters={filters || defaultFilters()}
            categoryLabel={category.label}
            categoryNames={category.mainCategoryNames}
            brands={category.brands}
            refinementList={refinementList}
            refinementListSelected={refinementListSelected}
            onHitClick={onCatalogHitClick}
          />
        </WrapperContainer>
      </Layout>
    </ThemeProvider>
  )
}

export default withPageTransition(CategoryView)
